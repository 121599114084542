.loading_container {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.container {
  font-size: 30px;
}

.header_section {
  background: linear-gradient(90.04deg, rgba(32, 122, 196, 0.48) 38.9%, rgba(32, 122, 196, 0) 72.96%);

  .header_bg {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    .header_bg_img {
      height: 997px;
      position: relative;
      width: 100%;
    }
  }

  .header_container {
    display: flex;
    margin: 0 auto;
    max-width: 1184px;
    padding: 120px 0 100px;
    position: relative;

    .header_left {
      text-align: left;
      width: 50%;
      z-index: 1;

      .header_get_started {
        color: #fff;
        letter-spacing: 1.18013px;
        margin-bottom: 15px;
      }

      .header_get_started_desc {
        color: #fff;
        margin-bottom: 40px;
        max-width: 482px;
      }

      .header_label {
        color: #fff;
        margin-bottom: 15px;
      }

      .header_input_group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 36px;
        width: 100%;
      }

      .header_input {
        background: #fff;
        border: 1px solid #cbcbcb;
        border-radius: 68px 0 0 68px;
        box-sizing: border-box;
        color: #808080;
        font-size: 0.5em;
        font-weight: 500;
        height: 55px;
        letter-spacing: 0.877417px;
        line-height: 120%;
        outline: unset;
        padding: 0 22px;
        width: 70%;
      }

      .header_input_btn {
        align-items: center;
        background: #ffe34f;
        border-radius: 0 68px 68px 0;
        color: #000;
        cursor: pointer;
        display: flex;
        height: 55px;
        justify-content: center;
        line-height: 100%;
        padding: 12px;
        width: 30%;

        svg {
          margin-left: 12px;
        }
      }

      .header_input_btn:hover {
        background: #ffcd4f;
      }
    }

    .header_right {
      position: relative;
      width: 50%;

      .item1 {
        position: absolute;
        right: 0;
      }

      .item2 {
        position: absolute;
        right: 330px;
        top: 73px;
      }

      .item3 {
        position: absolute;
        right: 0;
        top: 0;
      }

      .item4 {
        position: absolute;
        right: 480px;
        top: 0;
      }

      .big_item {
        background-image: url('/images/home/big_item.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 527px;
        position: absolute;
        right: 0;
        top: 68px;
        width: 640px;
        z-index: 0;
      }
    }
  }
}

.games_for_team {
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: space-between;
  margin: -65px auto 67px;
  max-width: 1184px;
  padding: 64px 99px;
  position: relative;

  .games_for_team_left {
    width: 48%;

    .games_for_team_left_title {
      position: relative;
      width: fit-content;
    }

    .games_for_team_left_title::after {
      background-color: #ffcd4f;
      bottom: -3px;
      content: '';
      height: 3px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    .games_for_team_left_title_desc {
      margin: 20px 0 40px;
    }

    .games_for_team_popular_game {
      cursor: pointer;
      margin-bottom: 20px;
      padding: 0.2em 0;
      position: relative;
      width: fit-content;

      &.selected {
        color: #207ac4;
      }

      &.selected::after {
        left: 0;
        right: auto;
        width: 100%;
      }
    }

    .games_for_team_popular_game:hover {
      color: #207ac4;
    }

    .games_for_team_popular_game::after {
      background-color: #207ac4;
      bottom: -5px;
      content: '';
      height: 0.1em;
      position: absolute;
      right: 0;
      transition-duration: 0.2s;
      transition-property: width;
      transition-timing-function: ease-out;
      width: 0;
    }
  }

  .games_for_team_right {
    margin-top: 50px;
    width: calc(52% - 47px);

    .game_screenshot_sliders {
      .game_screenshot_slider {
        height: 433px;
        position: relative;
        width: 244px !important;

        img {
          border-radius: 22px;
          cursor: pointer;
        }
      }
    }

    .games_for_team_spin {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .previous_game,
      .next_game {
        cursor: pointer;
        user-select: none;
        width: 34px;
      }

      .current_game {
        height: 105px;
        margin: 0 50px;
        width: 98.1px;
      }

      .arrow_space {
        width: 40px;
      }
    }
  }
}

.overview_section {
  background-color: #ffe34f;
  position: relative;

  .create_virtual_outing_bg_vector {
    height: 195px;
    position: relative;
    width: 100%;
    z-index: 0;
  }

  .overview_section_container {
    margin: -30px auto 0;
    max-width: 1184px;
    padding: 0 0 75px;

    .overview_section_header {
      margin: 0 auto;
      max-width: 560px;
      text-align: center;

      .overview_create_outing_title {
        margin: 0 auto 25px;
        position: relative;
        width: fit-content;
      }

      .overview_create_outing_title::after {
        background-color: #207ac4;
        bottom: -3px;
        content: '';
        height: 3px;
        left: 0;
        position: absolute;
        width: 100%;
      }

      .overview_create_outing_desc {
        margin-bottom: 35px;
      }

      .overview_cta {
        margin: 0 auto;
      }
    }

    .overview_section_body {
      padding: 64px 99px 0;

      .overview_section_body_item {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 75px;

        .overview_section_body_item_left {
          max-width: 380px;

          .overview_section_body_item_title {
            margin-bottom: 25px;
          }
        }

        .overview_section_body_item_right {
          width: 580px;
        }
      }

      .overview_section_body_item:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.get_your_team_involved {
  background-color: #fff;
  background-image: url('/images/home/get_your_team_involved_mask.png');
  background-position: right center;
  background-repeat: no-repeat;
  padding: 180px 0;
  position: relative;

  .get_your_team_involved_container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1184px;

    .get_your_team_involved_left {
      max-width: 480px;
      width: 45%;

      .get_your_team_involved_title {
        margin: 20px 0;
        position: relative;
        width: fit-content;
      }

      .get_your_team_involved_title::after {
        background-color: #5d34a6;
        bottom: -3px;
        content: '';
        height: 3px;
        left: 0;
        position: absolute;
        width: 100%;
      }

      .get_your_team_involved_desc {
        margin: 0 0 75px;
      }
    }

    .get_your_team_involved_right {
      width: 55%;

      .get_your_team_involved_right_row {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 30px;

        .get_your_team_involved_img_container {
          height: 150px;
          margin: 0 0 0 20px;
          position: relative;
          width: 280px;
        }
      }
    }
  }
}

.better_with_video {
  background-color: #207ac4;
  background-image: url('/images/home/better_with_video_mask.png');
  background-position: left center;
  background-repeat: no-repeat;
  padding: 120px 0 150px;
  position: relative;

  .better_with_video_container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1184px;

    .better_with_video_left {
      .better_with_video_left_img {
        height: 262px;
        position: relative;
        width: 582px;
      }
    }

    .better_with_video_right {
      max-width: 535px;

      .better_with_video_title {
        color: #fff;
        margin: 30px 0 20px;
        position: relative;
        width: fit-content;
      }

      .better_with_video_title::after {
        background-color: #ffcd4f;
        bottom: -3px;
        content: '';
        height: 3px;
        left: 0;
        position: absolute;
        width: 100%;
      }

      .better_with_video_desc {
        color: #fff;
        margin: 0 0 50px;
      }

      .better_with_video_platform_supports {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 432px;

        a {
          flex: 0 0 15%;
          max-width: 64px;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .header_section {
    .header_container {
      max-width: unset;
      padding: 100px 60px;

      .header_right {
        .item4 {
          right: 380px;
        }

        .big_item {
          height: 400px;
          top: 102px;
          width: 492px;
        }
      }
    }
  }

  .games_for_team {
    margin: -65px 60px 67px;
    max-width: unset;

    .games_for_team_right {
      .game_screenshot_sliders {
        .game_screenshot_slider {
          height: 355px;
          width: 200px !important;
        }
      }
    }
  }

  .overview_section {
    .overview_section_container {
      max-width: unset;
      padding: 0 60px 75px;

      .overview_section_body {
        padding: 64px 64px 0;

        .overview_section_body_item {
          .overview_section_body_item_right {
            width: 420px;
          }
        }
      }
    }
  }

  .get_your_team_involved {
    padding: 180px 60px;

    .get_your_team_involved_container {
      max-width: unset;

      .get_your_team_involved_left {
        .get_your_team_involved_desc {
          margin: 0 0 50px;
        }
      }

      .get_your_team_involved_right {
        .get_your_team_involved_right_row {
          .get_your_team_involved_img_container {
            height: 116px;
            width: 220px;
          }
        }
      }
    }
  }

  .better_with_video {
    padding: 120px 60px 150px;

    .better_with_video_container {
      max-width: unset;

      .better_with_video_left {
        margin-right: 20px;

        .better_with_video_left_img {
          height: 189px;
          width: 420px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .header_section {
    .header_container {
      padding: 100px 60px;

      .header_right {
        .item3 {
          right: 0;
        }

        .item4 {
          right: 335px;
        }

        .big_item {
          height: 352px;
          top: 160px;
          width: 450px;
        }
      }
    }
  }

  .games_for_team {
    margin: -50px 60px 60px;
    padding: 64px 80px;

    @media (max-width: 992px) {
      display: block;
    }

    .games_for_team_left {
      @media (max-width: 992px) {
        width: 100%;
      }
    }

    .games_for_team_right {
      @media (max-width: 992px) {
        margin-left: 0;
        margin-top: 40px;
        width: 100%;
      }

      .game_screenshot_sliders {
        @media (max-width: 992px) {
          margin: 0 auto;
          max-width: 400px;
        }

        .game_screenshot_slider {
          height: 320px;
          width: 180px !important;
        }
      }
    }
  }

  .overview_section {
    .overview_section_container {
      padding: 0 60px 75px;

      .overview_section_body {
        .overview_section_body_item {
          .overview_section_body_item_left {
            max-width: 350px;
          }

          .overview_section_body_item_right {
            width: 360px;
          }
        }
      }
    }
  }

  .get_your_team_involved {
    padding: 120px 60px;

    .get_your_team_involved_container {
      .get_your_team_involved_left {
        .get_your_team_involved_title {
          margin: 40px 0 20px;
        }
      }
    }
  }

  .better_with_video {
    padding: 120px 60px;

    .better_with_video_container {
      .better_with_video_right {
        .better_with_video_title {
          margin: 0 0 20px;
        }
      }

      .better_with_video_left {
        .better_with_video_left_img {
          height: 162px;
          width: 360px;
        }
      }

      .better_with_video_right {
        max-width: 480px;

        .better_with_video_platform_supports {
          width: 320px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .header_section {
    .header_container {
      display: block;
      padding: 100px 32px;

      .header_left {
        width: 100%;
      }

      .header_right {
        height: 228px;
        position: relative;
        width: 100%;

        .item3 {
          display: none;
        }

        .item4 {
          display: none;
        }

        .big_item {
          background-image: url('/images/home/big_item_mobile.png');
          height: 228px;
          left: 0;
          margin: 0 auto;
          right: 0;
          top: 0;
          width: 348px;
        }
      }
    }
  }

  .games_for_team {
    margin: -50px 32px 60px;
    padding: 64px;

    .games_for_team_left {
      width: 100%;
    }

    .games_for_team_right {
      margin-left: 0;
      margin-top: 40px;
      width: 100%;

      .game_screenshot_sliders {
        .game_screenshot_slider {
          height: 355px;
          width: 200px !important;

          @media (max-width: 600px) {
            height: 310px;
            width: 175px !important;
          }
        }
      }
    }
  }

  .overview_section {
    .create_virtual_outing_bg_vector {
      height: 95px;
    }

    .overview_section_container {
      padding: 0 32px 75px;

      .overview_section_body {
        .overview_section_body_item {
          flex-flow: wrap;
          justify-content: center;
          margin-bottom: 75px;

          .overview_section_body_item_left {
            text-align: center;

            .overview_section_body_item_desc {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }

  .get_your_team_involved {
    display: none;
  }

  .better_with_video {
    padding: 50px 32px;

    .better_with_video_container {
      flex-flow: wrap-reverse;
      justify-content: center;

      .better_with_video_right {
        text-align: center;

        .better_with_video_title {
          font-size: 37px;
          font-weight: 800;
          line-height: 46px;
          margin: 40px auto 20px;
        }

        .better_with_video_desc {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 30px;
        }

        .better_with_video_platform_supports {
          margin: 0 auto 30px;
          width: 50%;

          a {
            img {
              width: 100%;
            }
          }
        }
      }

      .better_with_video_left {
        .better_with_video_left_img {
          height: 176px;
          width: 400px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .header_section {
    .header_bg {
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;

      .header_bg_img {
        height: 997px;
        width: 100%;
      }
    }

    .header_container {
      padding: 100px 32px 76px;

      .header_left {
        .header_get_started {
          font-size: 37px;
          line-height: 46px;
        }

        .header_get_started_desc {
          font-size: 18px;
          line-height: 24px;
        }

        .header_label {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 15px;
        }

        .header_input_group {
          margin-bottom: 20px;
        }

        .header_input {
          border: 1px solid #cbcbcb;
          border-radius: 74px;
          font-size: 12px;
          height: 44px;
          margin: 0;
          padding: 0 15px;
          width: calc(70% - 35px);
        }

        .header_input_btn {
          border-radius: 74px;
          height: 44px;
          padding: 5px;
          width: calc(30% + 25px);

          svg {
            margin-left: 8px;
          }
        }
      }

      .header_right {
        height: 327px;

        .big_item {
          background-image: url('/images/home/big_item_mobile.png');
          height: 316px;
          right: 0;
          top: 0;
          width: 355px;
        }
      }
    }
  }

  .games_for_team {
    display: block;
    margin: -120px 32px 60px;
    padding: 0;

    .games_for_team_left {
      width: 100%;

      .games_for_team_left_title {
        font-size: 37px;
        font-weight: 800;
        line-height: 46px;
        margin: 0 54px;
        padding: 40px 0 0;
        text-align: center;
      }

      .games_for_team_left_title_desc {
        font-size: 18px;
        line-height: 24px;
        margin: 20px 54px 40px;
        text-align: center;
      }
    }

    .games_for_team_right {
      margin-left: 0;
      margin-top: 0;
      width: 100%;

      .game_screenshot_sliders {
        .game_screenshot_slider {
          height: 316.59px;
          width: 180px !important;
        }
      }

      .games_for_team_spin {
        margin-bottom: 20px;
      }

      .games_for_team_popular_game {
        cursor: pointer;
        margin-bottom: 20px;
        padding: 0.2em 0;
        position: relative;
        text-align: center;

        &.selected {
          color: #207ac4;
          text-decoration: underline;
          text-underline-position: under;
        }
      }

      .games_for_team_view_all {
        margin: 0 auto;
      }
    }
  }

  .overview_section {
    background-color: #ffe34f;
    background-image: unset;
    padding: 0;

    .create_virtual_outing_bg_vector {
      height: 75px;

      img {
        object-fit: cover;
      }
    }

    .overview_section_container {
      padding: 0 22px 50px;

      .overview_section_header {
        .overview_create_outing_title {
          font-size: 37px;
          line-height: 46px;
          max-width: 300px;
        }
      }

      .overview_section_body {
        padding: 40px 0 0;

        .overview_section_body_item {
          margin-bottom: 40px;
        }
      }
    }
  }

  .better_with_video {
    background-position: center bottom;
    background-size: 100% 40%;
    height: 875px;
    padding: 50px 32px 0;

    .better_with_video_container {
      .better_with_video_right {
        margin: 0;

        .better_with_video_title {
          font-size: 37px;
          font-weight: 800;
          line-height: 46px;
          margin: 20px auto;
        }

        .better_with_video_desc {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 50px;
        }

        .better_with_video_platform_supports {
          display: block;
          margin: 0 auto 60px;
          width: 100%;

          a {
            margin: 0 20px;

            img {
              width: 100%;
            }
          }
        }
      }

      .better_with_video_left {
        .better_with_video_left_img {
          height: 218px;
          width: 350px;
        }
      }
    }
  }
}

@media (max-width: 390px) {
  .header_section {
    .header_bg {
      .header_bg_img {
        height: 957px;
      }
    }

    .header_container {
      padding: 100px 22px;

      .header_left {
        .header_get_started {
          font-size: 34px;
          line-height: 42px;
        }
      }

      .header_right {
        height: 272px;

        .big_item {
          height: 250px;
          right: 20px;
          top: 10px;
          width: 280px;
        }
      }
    }
  }

  .games_for_team {
    .games_for_team_left {
      .games_for_team_left_title {
        margin: 0 40px;
      }

      .games_for_team_left_title_desc {
        margin: 20px 40px 40px;
      }
    }

    .games_for_team_right {
      .game_screenshot_sliders {
        .game_screenshot_slider {
          height: 298.49px;
          width: 170px !important;
        }
      }
    }
  }

  .better_with_video {
    .better_with_video_container {
      .better_with_video_left {
        .better_with_video_left_img {
          height: 192px;
          width: 311px;
        }
      }

      .better_with_video_right {
        .better_with_video_platform_supports {
          a {
            margin: 0 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .header_section {
    .header_container {
      .header_right {
        .big_item {
          right: -10px;
        }
      }
    }
  }

  .games_for_team {
    .games_for_team_right {
      .game_screenshot_sliders {
        .game_screenshot_slider {
          height: 263px;
          width: 150px !important;
        }
      }
    }
  }

  .better_with_video {
    .better_with_video_container {
      .better_with_video_left {
        .better_with_video_left_img {
          height: 182px;
          width: 290px;
        }
      }

      .better_with_video_right {
        .better_with_video_platform_supports {
          a {
            margin: 0 10px;
          }
        }
      }
    }
  }
}
